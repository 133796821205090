import React, { useState } from "react"
import { graphql, Link } from 'gatsby'
// https://github.com/angeloashmore/gatsby-source-prismic#Image-processing
import Img from "gatsby-image"

import Layout from '../components/layout'
import SEO from "../components/seo"
import Hero from '../components/hero'
import Filter from '../components/filter'
import Listing from '../components/listing'
import Banner from "../components/banner"

import { prettyCategory, listingCantons, listingsHasTags } from '../utils/helpers'

/** @jsx jsx */
import { jsx } from 'theme-ui'

const AllListings = (props) => {
    const listings = props.data.allPrismicEntreprise.edges.map( ({node: listing}) => listing )
    const category = props.pageContext.category
    const allSiteTags = props.pageContext.tags
    const geoZones = props.data.allPrismicGeozone.edges.map( ({ node }) => node )
    const hideFilters = props.data.prismicCategory.data.hide_filters 
    const htmlSeoText = props.data.prismicCategory.data.seo_text.html
    const metaTags = props.data.allPrismicTag.edges.map( ({node: metaTag}) => metaTag )

    // console.log("HIDE", hideFilters)
    // console.log("TAGS", allSiteTags)

    const [canton, setCanton] = useState(null)
    const [tags, setTags] = useState(new Set())

    // if canton is null then return all listings
    // if canton is set then return filter in only listings that have 
    var filteredListings = listings.filter( listing => {
        if ( ( canton === null ) || listingCantons(listing).includes(canton) ) return listing
        return null
    })

    // Then filter by tags (remember it's a Set) too, change it to arry first
    // For each selected tag, check that it is included in the listing's tags
    filteredListings = filteredListings.filter( listing => {
        if ( tags.size === 0 ) return listing
        if ( listingsHasTags(listing, tags) ) return listing
        return null
    })

    // provide Slug to Canton Name equivalence
    var zonesObject = {}
    geoZones.forEach( zone => zonesObject[zone.uid] = zone.data.name )

    var resultsNumber = filteredListings.filter( listing => listing !== null ).length

    const hero_image = props.data.prismicCategory.data.hero_image.fluid

    // useEffect(() => {
    //     console.log("CHANGE IN TAGS", tags)
    // }, [tags])

    return (
        <Layout>
            <SEO />

            {hero_image && 
                <Img 
                    fluid={hero_image} 
                    sx={{
                        mt: 5,
                        borderRadius: 1,
                    }}
                />
            }

            <Hero 
                resultsNumber={resultsNumber}
                category={category}
                canton={canton && zonesObject[canton]}
            />

            <Filter 
                geoZones={geoZones}
                allTags={allSiteTags}
                canton={canton}
                tags={tags}
                setCanton={setCanton}
                setTags={setTags}
                hideFilters={hideFilters}
            />

            <div>            
                {filteredListings.map( (listing, index) => {
                    let row = (
                        <React.Fragment key={listing.id}>
                            <Listing 
                                key={listing.id}
                                uid={listing.uid}
                                tags={listing.tags.filter( tag => tag !== category )}
                                data={listing.data}
                                prettyCategory={prettyCategory(category)}
                            />
                            {index > 3 && (index === 4 || index === 14 || index === 34) && <Banner position={index + 2} />}
                        </React.Fragment>
                    )

                    return row
                }
                )}
            </div>

            {htmlSeoText && <div sx={{mt: 5}}>
                <div dangerouslySetInnerHTML={{__html: htmlSeoText}}/>
            </div>}

            {metaTags && <div>
                {metaTags.map( tag => (
                    <Link 
                        to={`/${tag.uid}`} 
                        sx={{display: "block"}}
                        key={tag.uid}
                    >
                            {tag.data.name}
                    </Link>
                ))}
            </div>}
        </Layout>
    )
}

export default AllListings

export const AllListingsQuery = graphql`
    query AllListingsQuery($category: String!) {
        allPrismicEntreprise(
            filter: {data: {categories: {elemMatch: {category: {uid: {eq: $category }}}}, draft: {ne: true}}},
            sort: {fields: data___rank, order: ASC}
            ) {
            edges {
                node {
                    id
                    uid
                    ...ListingFragment
                }
            }
        }
        allPrismicGeozone {
            edges {
              node {
                uid
                data {
                  name
                }
              }
            }
        }
        prismicCategory(uid: {eq: $category}) {
            uid
            data {
              hide_filters
              hero_image {
                fluid {
                    ...GatsbyPrismicImageFluid
                }
              }
              seo_text {
                html
              }
            }
        }
        allPrismicTag(filter: {data: {categories: {uid: {eq: $category}}}}) {
            edges {
              node {
                uid
                data {
                    name
                }
              }
            }
        }
    }
`