/** @jsx jsx */
import { jsx, Box } from 'theme-ui'

import { MdAutorenew } from "react-icons/md"

const Filter = (props) => {
    const {
        geoZones,
        canton,
        allTags,
        setCanton,
        setTags,
        tags,
        hideFilters
    } = props

    // console.log('FROM FILTER', tags)

    return (
        <Box sx={{mb: 5}}>
            <div>
                <h4 sx={sectionTitle}>
                    Choisissez un canton
                </h4>

                <div sx={{
                    overflowX: ['scroll', 'scroll', 'visible'],
                    display: ['flex', 'flex', 'block'],
                    mx: ['-15px', '-15px', '0'],
                    py: 4, px: ['15px', '15px', '0'],
                    button: buttonStyle,
                }}>
                    {geoZones.map( zone => (
                        <button 
                            key={zone.uid}
                            onClick={() => setCanton(zone.uid)}
                            sx={canton === zone.uid ? {color: 'primary'} : {color: 'grey'}}
                            className="filter"
                            data-filter={zone.uid}
                        >
                            {zone.data.name}
                        </button>
                    ))}
                    {canton && <button 
                        onClick={() => setCanton(null)} 
                        sx={{color: 'grey'}}
                        className="filter"
                        data-filter="reset--canton"
                    >
                        <MdAutorenew sx={{mb: '-2px', color: 'primary'}} /> RESET
                    </button>}
                </div>
            </div>

            {hideFilters === false && <div>
                <h4 sx={sectionTitle}>
                    Choisissez vos filtres
                </h4>

                <div sx={{
                    py: 4, px: ['15px', '15px', '0'],
                    button: buttonStyle,
                }}>
                    {allTags.sort( (a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map( tag => (
                        <button 
                            key={tag}
                            className="filter"
                            data-filter={tag}
                            onClick={(e) => setTags(prevTags => {
                                var newTags = new Set(prevTags)
                                if ( prevTags.has(tag) ) {
                                    newTags.delete(tag)
                                } else {
                                    newTags.add(tag)
                                }
                                // console.log("NEW TAGS", newTags)
                                return newTags
                            })}
                            sx={tags.has(tag) ? {color: 'secondary'} : {color: 'grey'}}
                        >
                            {tag}
                        </button>
                    ))}
                    {tags.size > 0 && <button 
                        onClick={() => setTags(prevTags => {
                            var newTags = new Set(prevTags)
                            // console.log(newTags)
                            newTags.clear()
                            return newTags
                        })} 
                        sx={{color: 'grey'}}
                        className="filter"
                        data-filter="reset--tags"
                    >
                        <MdAutorenew sx={{mb: '-2px', color: 'primary'}} /> RESET
                    </button>}
                </div>
            </div>
            }
        </Box>
    )
}

export default Filter

const sectionTitle = {
    mb: 2, mt: 0, 
    textTransform: 'uppercase', fontFamily: 'body', 
    fontSize: 1, color: 'grey'
}

const buttonStyle = {
    display: 'inline',
    px: 3, py: 3, mr: 3, mb: 3,
    border: 'none', borderRadius: 0,
    bg: 'background',
    minWidth: ['200px', '180px', '150px'],
    boxShadow: 0,
    fontSize: 2, fontWeight: '700',
    textTransform: 'uppercase', fontFamily: 'body', 
    cursor: 'pointer',
    '&:focus': {
        outline: 'none',
    },
    '&:hover': {
        color: 'primary',
    },
}